import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import { Container, Row, Col, Breadcrumb, Button, Card } from "react-bootstrap";
import CarImg from "../images/aboutus.jpg";
import GenMan from '../images/genmanimg.svg';
import DivMan from "../images/divmanimg.svg";
import OpMan from "../images/opmanimg.svg";
import FleetMan from "../images/fleetmanimg.svg";
import axios from "axios";
import { BASE_URL, Language } from "../config";
import { Helmet } from "react-helmet";
import Menubar from "../components/Navbar/Menubar_new";
import style from '../styles/home.module.css';
import CarImg2 from "../images/carimg.png";


const AboutUs = () => {
    const [aboutdata, setaboutdata] = useState('');
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        fetchaboutus();
        fetchteams();
    }, [])
    const fetchaboutus = async () => {
        await axios.get(BASE_URL + '/api/about-us').then(({ data }) => {
            setaboutdata(data.data);
        })


    }
    const fetchteams = async () => {
        await axios.get(BASE_URL + '/api/manage-teams').then(({ data }) => {
            setTeams(data.data);
        })
    }

    const about = aboutdata.content ? aboutdata.content : [];

    const { t } = useTranslation();
    const mgtTeamData = [
        {
            id: '1',
            img: GenMan,
            name: 'Mr Sandeep Ghai',
            role: t("teamMembers.postFirst"),
        },
        {
            id: '2',
            img: DivMan,
            name: 'Anbarasu Kaliyamoorthy',
            role: t("teamMembers.postSecond"),
        },
        {
            id: '3',
            img: OpMan,
            name: 'Binu Harigopi',
            role: t("teamMembers.postThird"),
        },
        {
            id: '4',
            img: FleetMan,
            name: 'Rajesh Balasubramanian',
            role: t("teamMembers.postFourth"),
        },
    ];
    return (

        <div className="overflow">
            <Helmet>
                <meta name="title" content={aboutdata.meta_title} />
                <meta name="description" content={aboutdata.meta_description} />
                <meta name="keywords" content={aboutdata.meta_keywords} />
                <meta name="canonical-tags" content={aboutdata.canonical_tags} />
                <meta name="twitter-cards" content={aboutdata.twitter_cards} />
                <meta name="og-tags" content={aboutdata.og_tags} />
            </Helmet>

            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className={style.p50} xs={12} md={7}>
                        <h1>{localStorage.getItem("language") == "ar" ? aboutdata.title_ar && aboutdata.title_ar : aboutdata.title && aboutdata.title}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/"><div>{t("menu.home")}</div></Breadcrumb.Item>
                            <Breadcrumb.Item active  >
                                <div className="blog-breadcrumb-active">{localStorage.getItem("language") == "ar" ? aboutdata.title_ar && aboutdata.title_ar : aboutdata.title && aboutdata.title}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={5}>
                        <img src={CarImg2} alt="" width="387px" className={`mobile-hidden ${style.CarImg}`} />
                    </Col>
                </Row>
            </Container>

            <Container className={`aboutUsCont ${style.mt100}`} >

                <Col>
                    <h1 className={`center pack-name mt-4 ${style.aboutHeading}`}>{localStorage.getItem("language") == "ar" ? aboutdata.title_ar && aboutdata.title_ar : aboutdata.title && aboutdata.title}</h1>
                    <br />
                    <br />

                    <Row>
                        <Col md={6}>
                            <img src={CarImg} className={style.AboutImg} />
                        </Col>
                        <Col md={6}>
                            <p className="text-muted mt-4" dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? aboutdata.content_ar : about.length > 500 ? about.substring(0, 1380) : about }}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <p className="text-muted mt-4" dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? aboutdata.content_ar : about.length > 500 ? about.substring(1400, 2500) : about }}></p>
                        </Col>
                        <Col md={6}>
                            <img src={CarImg} className={style.AboutImg} />
                        </Col>
                    </Row>
                </Col>

            </Container>

            {/* <Container className={`aboutUsContMobile ${style.mt100}`}>
            <h3 className={`center pack-name mt-4`}>{localStorage.getItem("language") == "ar" ? aboutdata.title_ar && aboutdata.title_ar : aboutdata.title && aboutdata.title}</h3>
            <div className="text-muted mt-4"  dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? aboutdata.content_ar && aboutdata.content_ar : aboutdata.content && aboutdata.content }}></div>
            </Container> */}

            <Container fluid className="mgtCont ">
                <h1 className="text-center pack-name">{t("manageTeam")}</h1>
                <p className="text-center text-muted pt-2">{t("manageTeamText")}</p>

                <Row className="pt-5">
                    {teams.map((data) => (
                        <Col key={data.id} xs={12} md={3}>
                            <Card className={`text-center ${style.aboutCard}`}>
                                <img src={BASE_URL + '/storage/' + data.image} className="mgtImg" alt="" />
                                <Card.Title className="pt-3">{localStorage.getItem("language") == "ar" ? data.name_ar && data.name_ar : data.name && data.name}</Card.Title>
                                <Card.Text className="text-muted">{localStorage.getItem("language") == "ar" ? data.designation_ar && data.designation_ar : data.designation && data.designation}</Card.Text>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Container>


            <Footer />
        </div>
    );
};

export default AboutUs;
