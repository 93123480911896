import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import LightingEquipmentCategory from '../components/HomepageComponents/LightingEquipmentCategory';
import TopProducts from "../components/HomepageComponents/TopProducts";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Modal,
    Button,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";



async function fetchtopProducts(id) {

  axios.get(BASE_URL + '/api/top-selling-product/' + 69, {
  }).then(({ data }) => {

  
  })
}
const LightingEquipment = () => {
    const { t } = useTranslation();
    const [LightingEquipment, setLightingEquipment] = useState([]);
    const [open, setOpen] = useState(false);
   
    return (
        <div className="overflow">
        <Helmet>
        <title>Lighting Equipment Suppliers in Oman for Workshops - Cube Oman</title>
        <meta name="description" content="" />
        <meta name="keywords" content="Lighting Equipment"/>
        <link rel="canonical" href="https://www.cubeoman.com/lighting-equipment" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("LightingEquipmentTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("LightingEquipmentTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>

            </Container>
            
            <LightingEquipmentCategory />

            <Container fluid className="testimonials-bg">
            <div  fluid className="container">
      <Col xs={12} md={12} sm={12}>
     
                        <h1>{t("LightingEquipmentTitle")}</h1>
                        <p align="justify">At Cube Oman, wheel balancer products are essential for maintaining the proper balance of vehicle tires, which improves performance, safety, and tire lifespan. Wheel balancing ensures that the weight is evenly distributed around the wheel, preventing uneven tire wear, vibrations, and handling issues.
                        </p>
                        
                        <h4> Key Wheel Balancer Products:
                        </h4>
                        <p align="justify"><b>Automatic Wheel Balancers: </b> These machines use advanced sensors and precision tools to detect weight imbalances in vehicle wheels. Once the imbalance is identified, small weights are applied to specific areas of the wheel to ensure smooth rotation at high speeds.
                        </p>
                        <p align="justify"><b>Manual Wheel Balancers: </b> While less advanced, manual balancers are still effective for smaller shops or quick balancing jobs. They require more hands-on work but offer cost-effective solutions for basic wheel balancing.
                        </p>
                        <p align="justify"><b>Wheel Balancer Accessories: </b> Cube Oman also offers accessories such as balancing weights, cones, and clamps, ensuring all types of wheels can be properly balanced.
                        </p>
                        
                        <p align="justify">These high-tech and manual products ensure that Cube Oman provides accurate and reliable wheel balancing services, enhancing the driving experience.
                        </p>
                       
                       
                    </Col></div></Container>
            <Footer />
        </div>
    );
};

export default LightingEquipment;
