import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
  Container,
  Button,
  Row,
  Col,
  Breadcrumb,
  Card,
  Form,
} from "react-bootstrap";

import CarImg from "../images/carimg.jpg";
import HomeIcon from '../images/homegroupicon.svg';
import CallIcon from '../images/callgroupicon.svg';
import MailIcon from '../images/mailgroupicon.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import Swal from "sweetalert2";
import LocIcon from "../images/Icons/location.png";



const Star = ({ starId, marked }) => {
  return (
    <span
      star-id={starId}
      role="button"
      className="star-style"
    >
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};


const ContactUs = () => {
  const [contactdata, setContactdata] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState({});
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState();
  const [address, setAddress] = useState([]);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [emailFeed, setEmailFeed] = useState('');
  const [errors, setErrors] = useState('');
  const [file, setFile] = useState('');

  const [selection, setSelection] = useState(0);

  // Manages rating selection
  const [rating, setRating] = useState(0);

  const hoverOver = event => {
    let starId = 0;
    if (event && event.target && event.target.getAttribute("star-id")) {
      starId = event.target.getAttribute("star-id");
    }
    setSelection(starId);
  };


  const formdata = new FormData();
  formdata.append(["first_name"], firstname);
  formdata.append(["last_name"], lastname);
  formdata.append(["email"], emailFeed);
  formdata.append(["mobile_number"], mobile);
  formdata.append(["message"], message);
  formdata.append(["rating"], rating);
  formdata.append(["image"], file);


  useEffect(() => {
    fetchContactData();
    fetchContactAddress();
  }, []);

  const fetchContactData = async () => {
    await axios.get(BASE_URL + '/api/site-settings').then(({ data }) => {
      setContactdata(data.data)
    });
  }

  const fetchContactAddress = async () => {
    await axios.get(BASE_URL + '/api/company-address').then(({ data }) => {
      setAddress(data.data)
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setError('');
    axios.post(BASE_URL + '/api/contact-us', { 'name': name, 'email': email, 'mobile_number': mobile, 'message': message, 'subject': subject }).then(({ data }) => {
      if (data.status_code === 200) {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        document.getElementById("contactpage").reset();
      }
    }).catch(({ response }) => {

      if (response.status == '422') {
        setError(response.data.message)
      }
    });
  }


  function handleSubmitFeedback(e) {
    e.preventDefault();
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(BASE_URL + '/api/feedback', formdata, config).then(({ data }) => {
      setFirstname(null)
      setLastname(null)
      setEmailFeed(null)
      setMobile(null)
      setMessage(null)
      setRating(0)
      if (data.status_code === 200) {
        setErrors('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        document.getElementById("feedbackpage").reset();
      }
    }).catch(({ response }) => {
      if (response.status == '422') {
        setErrors(response.data.message);

      }
    });
  }


  const handleOnChange = e => {
    setFile(e.target.files[0]);
  };

  const OnChangeFirstName = e => {
    const { value } = e.target;
  

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setFirstname(value);
    }
  }

  const OnChangeLastName = e => {
    const { value } = e.target;
 

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setLastname(value);
    }
  }

  const OnChangeName = e => {
    const { value } = e.target;

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setName(value);
    }
  }

  const OnEmailValidation = e => {
    const { value } = e.target;

    const re = /^[A-Za-z@.1-9]+$/;
    if (value === "" || re.test(value)) {
      setEmail(value);
    }
  }

  const OnEmailFeedValidation = e => {
    const { value } = e.target;

    const re = /^[A-Za-z@.1-9]+$/;
    if (value === "" || re.test(value)) {
      setEmailFeed(value);
    }
  }



  const { t } = useTranslation();
  return (
    <div className="overflow">
        <Helmet>
        <title>Contact US - Cube Oman</title>
        <meta name="description" content="Contact US - Cube Oman" />
        <meta name="keywords" content="Contact US"/>
        <link rel="canonical" href="https://www.cubeoman.com/contact-us" />
        </Helmet>
      <Header />
      <Menubar />
      <Container fluid className="company-breadcrumb">
        <Row>
          <Col className="breadcrumb-padding" xs={12} md={8}>
            <h1>{t("contactTitle")}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active><div className="blog-breadcrumb-active">{t("contactTitle")}</div></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xs={12} md={4}>
            <img src={CarImg} alt="" width="387px" className="CarImg mobile-hidden" />
          </Col>
        </Row>
      </Container>
      <div className="ContactUsCont">
       
        <Container className="locationCont">
          <Row>
            <Col xs={12} md={6}>
              <Container className="feedbackInfoCont">
                <h1>{t("custLinks.feedback")}</h1>
                <p className="text-muted">{t("contactInfoText")}</p>
                <Form id="feedbackpage">
                  <Row className="contact-mb-4">
                  <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group as={Col} controlId="formGridName">
                      <Form.Control onChange={OnChangeFirstName} value={firstname} maxLength={12} type="text" placeholder={t("address.first_name")} />
                      {errors.first_name && (
                        <span className="alert-danger danger">{errors.first_name}</span>
                      )}
                    </Form.Group>
                    </Col>
                    <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group as={Col} controlId="formGridName">
                      <Form.Control type="text" onChange={OnChangeLastName} maxLength={12} value={lastname} placeholder={t("address.last_name")} />
                      {errors.last_name && (
                        <span className="alert-danger danger">{errors.last_name}</span>
                      )}
                    </Form.Group>
                    </Col>
                  </Row>

                  <Row className="contact-mb-4" >
                    <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group  controlId="formGridEmail">
                      <Form.Control onChange={OnEmailFeedValidation} value={emailFeed} type="email" placeholder={t("contactInfoField.email")}  />
                      {errors.email && (
                        <span className="alert-danger danger">{errors.email}</span>
                      )}
                    </Form.Group>
                    </Col>
                    <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group  controlId="formGridPhone">
                      <Form.Control type="number" onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder={t("contactInfoField.phone")} />
                      {errors.mobile_number && (
                        <span className="alert-danger danger">{errors.mobile_number}</span>
                      )}
                    </Form.Group>
                    </Col>

                  </Row>
                  <Row className="contact-mb-4">
                    <Form.Group controlId="messageForm.ControlTextarea1">

                      <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder="Message" as="textarea" rows={3} />
                      {errors.message && (
                        <span className="alert-danger danger">{errors.message}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Col className="">
                  <Row>
                    <Col md={6} xs={12} sm={12} className="upload-block">

                      <Form.Group as={Col} controlId="formGridPhone">
                        Select Rating:
                        <div
                          onMouseOver={hoverOver}
                          onMouseOut={() => hoverOver(null)}
                          onClick={event => setRating(event.target.getAttribute("star-id"))}
                        >
                          {Array.from({ length: 5 }, (v, i) => (
                            <Star starId={i + 1} marked={selection ? selection > i : rating > i} />

                          ))}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} xs={12} className="upload-block">
                      <Form.Group as={Col} controlId="formGridPhone">

                        <div >Upload Photo</div>
                        <input type="file" className='mt-2' onChange={handleOnChange}  id={file ?"" : "rgba"} />
                        {errors.image && (
                          <span className="alert-danger danger">{errors.image}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  </Col>



                  <Button onClick={firstname && lastname && emailFeed && message && rating ? handleSubmitFeedback : () => Swal.fire("Kindly Enter All Details")} variant="danger" className="feedbackMsgBtn mt-2">Submit Now</Button>
                </Form>
              </Container>
            </Col>
            <Col xs={12} md={6}>
              <Container className="contactInfoCont">
                <h1>{t("contactInfo")}</h1>
                <p className="text-muted">{t("contactInfoText")}</p>
                <Form id="contactpage">
                  <Row className="contact-mb-4">
                    <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group controlId="formGridName">

                      <Form.Control type="name" onChange={OnChangeName} value={name} placeholder={t("contactInfoField.name")} maxLength={12} />
                      {error.name && (
                        <span className="alert-danger danger">{error.name}</span>
                      )}
                    </Form.Group>
                    </Col>
                    <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group controlId="formGridEmail">

                      <Form.Control type="email" onChange={OnEmailValidation} value={email} placeholder={t("contactInfoField.email")} required />
                      {error.email && (
                        <span className="alert-danger danger">{error.email}</span>
                      )}
                    </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                  <Col md={6} xs={6} sm={6} className="width100">
                    <Form.Group controlId="formGridPhone">

                      <Form.Control type="number" onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder={t("contactInfoField.phone")} required />
                      {error.mobile_number && (
                        <span className="alert-danger danger">{error.mobile_number}</span>
                      )}
                    </Form.Group>
                    </Col>
                    <Col md={6} xs={6} sm={6} className="width100">

                    <Form.Group as={Col} controlId="formGridSubject">

                      <Form.Control type="subject" onChange={(e) => setSubject(e.target.value)} placeholder={t("contactInfoField.subject")} />
                      {error.subject && (
                        <span className="alert-danger danger">{error.message}</span>
                      )}
                    </Form.Group>
                      </Col>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group controlId="messageForm.ControlTextarea1">

                      <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder={t("contactInfoField.msg")} as="textarea" rows={6} />
                      {error.message && (
                        <span className="alert-danger danger">{error.message}</span>
                      )}
                    </Form.Group>
                  </Row>
                  {/* <Form.Check inline label={t("contactInfoField.termsPolicy")} /> */}
                  <Button variant="danger" onClick={name && email && mobile && subject && message ? handleSubmit : () => Swal.fire("Kindly Enter All Details")} className="contact-submit ">Submit Now</Button>
                </Form>
              </Container>
            </Col>

          </Row>

          {/* <Row className="mt-4">
          <Col xs={12} md={6}>
            <Container className="appointCont">
              
              <h1>{t("bookAppoint")}</h1>


              <Form className="pt-3">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">

                    <Form.Control type="name" placeholder={t("contactInfoField.name")} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPhone">

                    <Form.Control type="number" placeholder={t("contactInfoField.phone")} />
                  </Form.Group>
                </Row>
                <Row className="mt-4 mb-3">
                  <Form.Group as={Col} controlId="formGridManufacturer">

                    <Form.Control type="name" placeholder={t("appointFields.manu")} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridModel">

                    <Form.Control type="name" placeholder={t("appointFields.model")} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridYear">

                    <Form.Control type="number" placeholder={t("appointFields.year")} />
                  </Form.Group>
                </Row>
                <Row className="mt-4 mb-3">
                  <Form.Group as={Col} controlId="formGridType">

                    <Form.Control type="name" placeholder={t("appointFields.type")} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEngine">

                    <Form.Control type="name" placeholder={t("appointFields.engine")} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridTransmission">

                    <Form.Control type="name" placeholder={t("appointFields.transmit")} />
                  </Form.Group>
                </Row>
                <Row className="mt-4 mb-3">
                  <Form.Group as={Col} controlId="formGridDescription">

                    <Form.Control placeholder={t("appointFields.desc")} as="textarea" rows={4} />
                  </Form.Group>
                </Row>
                <Row className="mt-4 mb-3">
                  <Form.Group as={Col} controlId="formGridDate">

                    <Form.Control placeholder={t("appointFields.date")} />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridTime">

                    <Form.Control placeholder={t("appointFields.time")} />
                  </Form.Group>
                </Row>
                <Button variant="danger" className="mt-2">{t("appointBtn")}</Button>
              </Form>


            </Container>
          </Col>
          <Col xs={12} md={6}>
          <img src={MapImg} alt="" className="locMapImg" />
          </Col>

        </Row> */}
        </Container>
      </div>

            <div>

          {address.length == 0 ?
          ""
          :
          <Container className="center">
            <Row>
              { address.length == 1 ? 
              address && address.map((data) => (
                <Col xs={12} sm={12} md={12}>
                  <Card className="text-center contact-box" >
                    <Card.Title><div className="contact-heading mt-4">{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}</div></Card.Title>
                    <Card.Body className="add-card mt-4">
                      <div> <img src={LocIcon} className="loc-icon"  /> {localStorage.getItem('language') == 'ar' ? data.address_ar && data.address_ar : data.address && data.address}</div>
                      <Row>
                        <Col>
                        <Col className="mt-2 bold">{t("locationDetail.country")} : <span className="contact-data">{localStorage.getItem('language') == 'ar' ? data.countries.name_ar && data.countries.name_ar : data.countries.name && data.countries.name}</span></Col>
                        <Col className="mt-2 bold">{t("locationDetail.city")} : <span className="contact-data"> {localStorage.getItem('language') == 'ar' ? data.citys.name_ar && data.citys.name_ar : data.citys.name && data.citys.name}</span></Col>
                   
                        </Col>
                        <Col className="ml180">
                        <Col className="mt-2 bold">{t("locationDetail.state")} : <span className="contact-data"> {localStorage.getItem('language') == 'ar' ? data.states.name_ar && data.states.name_ar : data.states.name && data.states.name} </span></Col>
                        <Col className="mt-2 bold">{t("address.pincode")} : <span className="contact-data" >{data.zip_code && data.zip_code}</span></Col>
                        </Col>
                      </Row>
                      <Col>
                      <Col className="mt-2 bold">{t("address.email")} : <span className="contact-data">{data.email && data.email}</span></Col>
                      <Col className="mt-2 bold">{t("address.mobile_number")} : <span className="contact-data">{data.contact_number && data.contact_number}</span></Col>
                      </Col>
 
                      <div className="mt-4" dangerouslySetInnerHTML={{ __html: data.map_url && data.map_url }}></div>
                    </Card.Body>
                  </Card>
                </Col>

              ))
              :
              address && address.map((data) => (
                <Col xs={12} sm={6} md={4}>
                  <Card className="text-center contact-box" >
                    <Card.Title><h3>{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}</h3></Card.Title>
                    <Card.Body className="add-card">
                      <div>{localStorage.getItem('language') == 'ar' ? data.address_ar && data.address_ar : data.address && data.address}</div>
                      <div className="flex space-between">
                        <div className="mt-2">{t("locationDetail.country")} : {localStorage.getItem('language') == 'ar' ? data.countries.name_ar && data.countries.name_ar : data.countries.name && data.countries.name}</div>
                        <div className="mt-2">{t("locationDetail.state")} : {localStorage.getItem('language') == 'ar' ? data.states.name_ar && data.states.name_ar : data.states.name && data.states.name}</div>
                      </div>
                      <div className="flex space-between">
                        <div className="mt-2">{t("locationDetail.city")} : {localStorage.getItem('language') == 'ar' ? data.citys.name_ar && data.citys.name_ar : data.citys.name && data.citys.name}</div>
                        <div className="mt-2">{t("address.pincode")} : {data.zip_code && data.zip_code}</div>
                      </div>
                      <div className="mt-2">{t("address.email")} : {data.email && data.email}</div>
                      <div className="mt-2">{t("address.mobile_number")} : {data.contact_number && data.contact_number}</div>
                      <div className="mt-4" dangerouslySetInnerHTML={{ __html: data.map_url && data.map_url }}></div>
                    </Card.Body>
                  </Card>
                </Col>

              ))}

            </Row>
          </Container>

        }

          </div>
      
      <Footer />
    </div>
  );
};

export default ContactUs;