import React, {useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Card,
    Col,
    Breadcrumb,
    Button
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import RightArrowIcon from "../images/rightarrow.svg";
import CommentIcon from "../images/comment.svg";
import ShareIcon from "../images/shareoutlined.svg";
import { BASE_URL } from "../config";
import axios from "axios";
import Menubar from "../components/Navbar/Menubar_new"
const Blogs = () => {
    const [cardBlogs,setcardBlogs] = useState([]);
    useEffect(()=>{
fetchBlogs();
    },[]);
    const fetchBlogs = async()=>{
        await axios.get(BASE_URL+'/api/blogs').then(({data})=>{
            setcardBlogs(data.data)
        })
    }
    const {t} = useTranslation();
    const [blogdata,setBlogData]=useState([]);
    const language = localStorage.getItem("language");
    return (
        <div className="overflow">
        <Helmet>
        <title>Blogs</title>
        <meta name="description" content="Blogs - Cube Oman" />
        <meta name="keywords" content="Blogs"/>
        <link rel="canonical" href="https://www.cubeoman.com/blog" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("carBlog")}</h1>
                        <Breadcrumb>
                        <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("carBlog")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>

            <Container className="blogCont">
                <h1 className="text-center">{t("carBlog")}</h1>
                <p className="text-center text-muted">{t("homeBlogText")}</p>
                <Row className="pt-2">
                {    cardBlogs.map((blog) => {
            const blogContent = blog.content;

            return (
                <Col key={blog.id} xs={12} md={4} mb={4} className="mb-4">
                            <Card className="blogCard">
                                <Card.Img src={BASE_URL+'/storage/'+blog.image} alt="" />
                                <Card.Title className="m-2">{t(blog.title)}</Card.Title>
                                <Card.Text className="p-2 text-muted" dangerouslySetInnerHTML={{__html:blogContent && blogContent.length >200 ? blogContent.substring(0, 150) : blogContent }}></Card.Text>
                                <Card.Link className="p-2 blog-readmore" onClick={() => '/blog/:slug'} href="#">{t("cardReadMore")}
                                <img src={RightArrowIcon} alt="" />
                                <img className="blog-comment" src={CommentIcon} alt="" />
                                <img className="blog-share" src={ShareIcon} alt="" />
                                </Card.Link>
                                
                            </Card>
                        </Col>
            )
        })}
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Blogs;
