import React, { Suspense,createContext  } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18n"
import "@fontsource/montserrat";


import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from "react-use-cart";
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback="...">
      <CartProvider>
      <GoogleOAuthProvider clientId="283122404904-nj41m4r2iqdepht35r30g8kqrgaabj7q.apps.googleusercontent.com">
        <App />
        </GoogleOAuthProvider>
        </CartProvider>
      </Suspense>



    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();