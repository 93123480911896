import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import axios from "axios";
import { BASE_URL } from "../config";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import Menubar from "../components/Navbar/Menubar_new";

const TermsConditions = () => {
    const [termsData, settermsData] = useState('');
    useEffect(() => {
        fetchTerm();
    }, []);
    const fetchTerm = async () => {
        await axios.get(BASE_URL + '/api/policy/terms_and_condition').then(({ data }) => {
            settermsData(data.data);
            // console.log(data.data)
        })
    }
    const { t } = useTranslation();
    return (
        <div className="overflow">
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{localStorage.getItem("language") == "ar" ? termsData.title_ar && termsData.title_ar : termsData.title && termsData.title}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="blog-breadcrumb-active">
                                {localStorage.getItem("language") == "ar" ? termsData.title_ar && termsData.title_ar :termsData.title && termsData.title}
                                </div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} width="387px" alt="" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>

            <Container className="termsCond">
                <h1 className="text-center">{localStorage.getItem("language") == "ar" ? termsData.title_ar && termsData.title_ar : termsData.title && termsData.title}</h1>
                <br />
                <ul >

                    <div
                        dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ?  termsData.content_ar && termsData.content_ar :  termsData.content && termsData.content  }}
                    ></div>

                </ul>

            </Container>

            <Footer />
        </div>
    );
};

export default TermsConditions;
