import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import TopProductsCategory from '../components/HomepageComponents/TopProductsCategory';
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Modal,
    Button,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";




async function fetchtopProducts(id) {

    axios.get(BASE_URL + '/api/best-deal-product/'+ 69, 74, 73, 62, {
    }).then(({ data }) => {
  
    
    })
  }
  

const TopProducts = () => {
    const { t } = useTranslation();
    const [topproducts, setTopProducts] = useState([]);
    const [open, setOpen] = useState(false);
   
    return (
        <div className="overflow">
        <Helmet>
        <title>Top Products - Cube Oman</title>
        <meta name="description" content="Top Products - Cube Oman" />
        <meta name="keywords" content="Top Products"/>
        <link rel="canonical" href="https://www.cubeoman.com/top-products" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("TopProductsTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("TopProductsTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>









                
            </Container>
            
            <TopProductsCategory/>
            <Footer />
        </div>
    );
};

export default TopProducts;