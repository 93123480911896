import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        // resources: {
        //     en: {
        //         translation: {
        //             welcometext: "Welcome to react-i18next tutorial"
        //         },
        //     },
        //     ar: {
        //         translation: {
        //             //Arabic translations here
        //             welcometext: "مرحبًا بك في البرنامج التعليمي رد فعل i18next"
        //         },
        //     },
        //     ja: {
        //         translation: {
        //             welcometext: "react-i18next チュートリアルへようこそ"
                    
        //         }
        //     }
        // },
        // lng: "en",
        fallbackLng: "en",
        detection: {
            order: ["path", "localStorage", "htmlTag", "cookie"],
            caches: ["localStorage", "cookie"],
        },
        interpolation: {

        }
    });

export default i18n;