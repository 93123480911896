import React, { useRef } from "react";
import style from '../styles/slider.module.css';
import { Row, Col } from 'react-bootstrap';
import ArrowRight from '../images/Icons/ArrowRight.png';
import ArrowLeft from '../images/Icons/ArrowLeft.png';
import RightArrow from '../images/Icons/RightArrow.png';
import LeftArrow from '../images/Icons/LeftArrow.png';
import Product from '../images/product.png';
import ProductBox from '../components/ProductBox/Productbox';

const data = [
  {
    id: 1,
    img: Product
  },
  {
    id: 2,
    img: Product
  },
  {
    id: 3,
    img: Product
  },
  {
    id: 4,
    img: Product
  },
  {
    id: 5,
    img: Product
  },
  {
    id: 6,
    img: Product
  },
  {
    id: 7,
    img: Product
  },
  {
    id: 8,
    img: Product
  },
  {
    id: 9,
    img: Product
  },
  {
    id: 10,
    img: Product
  },
  {
    id: 11,
    img: Product
  },
  {
    id: 12,
    img: Product
  }
]

const sideScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

const dataScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};



export default function Slider(props) {
  const { name, boldname, data , children} = props;
  const contentWrapper = useRef(null);

  const dataWrapper = useRef(null);

  return (
    <>

      <Col md={12} xs={12} className={style.container}>
        <div className="flex">
          <Col md={6} className={`flex ${style.heading}`} >
            <div className={style.fontsize}> Related </div>
            <div className={`${style.fontsize} ${style.boldName}`}> Products </div>
          </Col>
          <Col md={6} className={style.end}>
            <div className={`pointer ${style.ScrollButton}`} onClick={() => { sideScroll(contentWrapper.current, 25, 100, -10); }} >
              <img src={ArrowLeft} alt="Right" className="mb-1" />
            </div>

            <div className={style.ScrollButton} onClick={() => {
              sideScroll(contentWrapper.current, 25, 100, 10);
            }}>
              <img src={ArrowRight} alt="Right" className="mb-1" />

            </div>
          </Col>
          <div >
          </div>
        </div>
        <div className="flex mt-4">


          <div className={style.contentWrapper} ref={contentWrapper}>

            {/* {data.map(i => (
              <Col md={3} xs={12}>
                <ProductBox 
                discountPrice={i.product_options[0] && i.product_options[0].price}
                actualPrice={i.product_options[0] && i.product_options[0].mrp}
                name={i.name && i.name}
                discount_perc={i.product_options[0] && i.product_options[0].discount_percentage}
                img={i.image}
                />
              </Col>
            ))} */}
            {children}
          </div>


        </div>

      </Col>
    </>
  );
}

