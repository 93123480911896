import React, { useRef, useState, useEffect } from "react";
import style from '../../styles/slider.module.css';
import { Row, Col } from 'react-bootstrap';
import ArrowRight from '../../images/Icons/ArrowRight.png';
import ArrowLeft from '../../images/Icons/ArrowLeft.png';
import RightArrow from '../../images/Icons/RightArrow.png';
import LeftArrow from '../../images/Icons/LeftArrow.png';
import { BASE_URL } from "../../config";
import axios from "axios";
import { useTranslation } from "react-i18next";



const sideScroll = (
    element,
    speed,
    distance,
    step
) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step * 2 - 10;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};

const dataScroll = (
    element,
    speed,
    distance,
    step
) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step * 2 - 10;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};



export default function Slider(props) {
    const { name, boldname, children, product } = props;
    const contentWrapper = useRef(null);

    const { t } = useTranslation();
    const dataWrapper = useRef(null);

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchproductcategory()
    }, [])

    async function fetchproductcategory() {

        axios.get(BASE_URL + '/api/product-categories/', {
        }).then(({ data }) => {

            setCategories(data.data);

        })
    }

    return (
        <>

            <Col md={12} xs={12} className={style.container}>
                <Col>
                    <Row>
                        <Col md={6} className={`flex ${style.heading}`} >
                            <div className={style.fontsize}> {name} </div>
                            <div className={`${style.fontsize} ${style.boldName}`}> {boldname} </div>
                        </Col>
                        <Col md={6} sm={12} xs={12}>
                            <Col md={12} sm={12} xs={12} className={`flex `}>

                                <div className={`pointer ${style.ScrollButton}`} onClick={() => { dataScroll(dataWrapper.current, 25, 100, -10); }}>
                                    <img src={ArrowLeft} alt="Right" className="mb-1" />
                                </div>
                                <div className={style.contentWrapper} ref={dataWrapper} >
                                    <div className="flex">
                                        <div onClick={() => product(0)} className="pointer">{t("productTab.all")}</div>
                                        {categories.map((data) => (
                                            <div className={style.menuItem} eventKey={data.id && data.id} onClick={() => product(data.id)}>
                                                {localStorage.getItem("language") == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={`pointer ${style.ScrollButton}`} onClick={() => {
                                    dataScroll(dataWrapper.current, 25, 100, 10);
                                }}>
                                    <img src={ArrowRight} alt="Right" className="mb-1" />

                                </div>


                            </Col>
                        </Col>
                    </Row>
                </Col>
                {/* <div className="flex" >
                    
                    <div >
                        <Col md={6}>
                            <Col className={`flex ${style.Menu}`}>

                                <div className={`pointer ${style.ScrollButton}`} onClick={() => { dataScroll(dataWrapper.current, 25, 100, -10); }}>
                                <img src={ArrowLeft} alt="Right"  className="mb-1"  />
                                </div>
                                <div className={style.contentWrapper} ref={dataWrapper} >
                                    <div className="flex">
                                        <div onClick={() => product(0)} className="pointer">{t("productTab.all")}</div>
                                        {categories.map((data) => (
                                         <div className={style.menuItem} eventKey={data.id && data.id } onClick={() => product(data.id)}>
                                            {localStorage.getItem("language") == 'ar' ? data.name_ar && data.name_ar : data.name && data.name} 
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className={style.ScrollButton} onClick={() => {
                                    dataScroll(dataWrapper.current, 25, 100, 10);
                                }}>
                                <img src={ArrowRight} alt="Right" className="mb-1"  />

                                </div>


                            </Col>
                        </Col>
                    </div>
                </div> */}
                <div className="flex mt-4">
                    <div onClick={() => { sideScroll(contentWrapper.current, 25, 100, -10); }}>
                        <img src={LeftArrow} alt="Right" className={`pointer ${style.Scroller}`} />
                    </div>

                    <div className={style.contentWrapper} ref={contentWrapper}>

                        {children}
                    </div>
                    <div onClick={() => {
                        sideScroll(contentWrapper.current, 25, 100, 10);
                    }}>
                        <img src={RightArrow} alt="Right" className={`pointer ${style.Scroller}`} />
                    </div>

                </div>

            </Col>
        </>
    );
}

