import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Menubar from "../components/Navbar/Menubar_new"
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Form,
    Breadcrumb,
    Button
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import { BASE_URL } from "../config";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from 'moment';



const BookAppointment = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [model, setModel] = useState('');
    const [year, setYear] = useState('');
    const [type, setType] = useState('');
    const [engine, setEngine] = useState('');
    const [transmission, setTransmission] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [error, setError] = useState('');
    const [carmake, setCarMake] = useState([]);
    const [carmodel, setCarModel] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [selectedModel, setSelectedModel] = useState();
    const [selectedMake, setSelectedMake] = useState();
    const [make, setMake] = useState();
    const [metaData, setMetaData] = useState([]);

  







    const handlSubmit = async (e) => {
        e.preventDefault();
        setError('');
        await axios.post(BASE_URL + '/api/book-appointment', {
            "name": name,
            "email": email,
            "mobile_number": phonenumber,
            "description": description
        }).then(({ data }) => {
            if (data.status_code === 200) {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("bookappointment").reset();
            }
        }).catch(({ response }) => {
            if (response.status === 422) {
                setError(response.data.message)
            }
        })
    }
    return (
        <div className="overflow">
        <Helmet>
        <title>Get Quotation</title>
        <meta name="description" content="Get Quotation" />
        <meta name="keywords" content="Get Quotation"/>
        <link rel="canonical" href="https://www.cubeoman.com/best-deals" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("getquotation")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active >
                                <div className="blog-breadcrumb-active">
                                    {t("getquotation")}
                                </div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>

            <div className="bookAppointCont">
                <Row>
                    <Col xs={12} md={4} sm={12}>
                        <h1>{t("GetQuotation")}</h1>
                        <p className="text-muted">{t("appointText.appointTextFirst")}

                        </p>
                        
                    </Col>
                    <Col xs={12} md={8} sm={12}>
                        <div className="aptCont">
                            <Form id="bookappointment">
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridName">

                                        <Form.Control type="text" onChange={(e) => setName(e.target.value)} placeholder={t("contactInfoField.name")} />
                                        {error.name && (
                                            <span className="alert-danger danger">{error.name}</span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPhone">

                                        <Form.Control type="number" onChange={(e) => setPhoneNumber(e.target.value)} placeholder={t("contactInfoField.phone")} />
                                        {error.phonenumber && (
                                            <span className="alert-danger danger">{error.phonenumber}</span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPhone">

                                        <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                                        {error.email && (
                                            <span className="alert-danger danger">{error.email}</span>
                                        )}
                                    </Form.Group>
                                </Row>
                                
                                {/* <Row className="mt-4 mb-3">
                                    <Form.Group as={Col} controlId="formGridType">
                                        <Form.Select className="text-muted"


                                        >
                                            <option>{t("appointFields.type.name")}</option>
                                            <option>{t("appointFields.type.name1")}</option>
                                            <option>{t("appointFields.type.name2")}</option>
                                            <option>{t("appointFields.type.name3")}</option>
                                            <option>{t("appointFields.type.name4")}</option>

                                        </Form.Select>
                              
                                        {error.type && (
                                            <span className="alert-da\nger danger">{error.type}</span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridEngine">
                                        <Form.Select className="text-muted"


                                        >
                                            <option>{t("appointFields.engine.name")}</option>
                                            <option>{t("appointFields.engine.name1")}</option>
                                            <option>{t("appointFields.engine.name2")}</option>
                                            <option>{t("appointFields.engine.name3")}</option>
                                            <option>{t("appointFields.engine.name4")}</option>
                                            <option>{t("appointFields.engine.name5")}</option>
                                            <option>{t("appointFields.engine.name6")}</option>

                                        </Form.Select>
                                        {error.engine && (
                                            <span className="alert-danger danger">{error.engine}</span>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTransmission">

                                        <Form.Control type="name" onChange={(e) => setTransmission(e.target.value)} placeholder={t("appointFields.transmit")} />
                                        {error.transmission && (
                                            <span className="alert-danger danger">{error.transmission}</span>
                                        )}
                                    </Form.Group>
                                </Row> */}
                                <Row className="mt-4 mb-3">
                                    <Form.Group as={Col} controlId="formGridDescription">

                                        <Form.Control onChange={(e) => setDescription(e.target.value)} placeholder={t("appointFields.desc")} as="textarea" rows={4} />
                                        {error.description && (
                                            <span className="alert-danger danger">{error.description}</span>
                                        )}
                                    </Form.Group>
                                </Row>
                                {/* <Row className="mt-4 mb-3">
                                    <Form.Group as={Col} controlId="formGridDate">

                                        <Form.Control type="date" onChange={(e) => setDate(e.target.value)} placeholder={t("appointFields.date")} />
                                        {error.date && (
                                            <span className="alert-danger danger">{error.date}</span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridTime">

                                        <Form.Control type="time" onChange={(e) => setTime(e.target.value)} placeholder={t("appointFields.time")} />
                                        {error.time && (
                                            <span className="alert-danger danger">{error.time}</span>
                                        )}
                                    </Form.Group>
                                </Row> */}
                                <Button variant="danger" onClick={handlSubmit} className="mt-2">{t("appointBtn")}</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </div>
    );
};

export default BookAppointment;
