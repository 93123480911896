import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Form,
  Button
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import axios from "axios";
import { BASE_URL } from "../config";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Menubar from "../components/Navbar/Menubar_new";
import ReactStars from "react-stars";


const Star = ({ starId, marked }) => {
  return (
    <span
      star-id={starId}
      role="button"
      className="star-style"
    >
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};


const
  Feedback = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState({});
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [file, setFile] = useState('');
    const navigate = useNavigate();
    const formdata = new FormData();
    const [selection, setSelection] = React.useState(0);
    const [rating, setRating] = useState(0);
    const hoverOver = event => {
      let starId = 0;
      if (event && event.target && event.target.getAttribute("star-id")) {
        starId = event.target.getAttribute("star-id");
      }
      setSelection(starId);
    };
    formdata.append(["first_name"], firstname);
    formdata.append(["last_name"], lastname);
    formdata.append(["email"], email);
    formdata.append(["mobile_number"], mobile);
    formdata.append(["message"], message);
    formdata.append(["rating"], rating);
    formdata.append(["image"], file);




    function handleSubmit(e) {
      e.preventDefault();
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(BASE_URL + '/api/feedback', formdata, config).then(({ data }) => {
        setEmail(null)
        setFile(null)
        setFirstname(null)
        setLastname(null)
        setMobile(null)
        setMessage(null)
        if (data.status_code === 200) {
          setError('')
          Swal.fire({
            text: data.message,
            icon: "success"
          })
          document.getElementById("feedbackpage").reset();
        }
      }).catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.message);
        }
      });
    }

    const ratingChanged = (newRating) => {
      setRating(newRating)
      // console.log(newRating)
    }

    const handleOnChange = e => {
      setFile(e.target.files[0]);
    };

    const OnChangeFirstName = e => {
      const { value } = e.target;
  
  
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        setFirstname(value);
      }
    }
  
    const OnChangeLastName = e => {
      const { value } = e.target;
  
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        setLastname(value);
      }
    }


    const { t } = useTranslation();
    return (
      <div className="overflow">
       <Helmet>
        <title>Feedback - Cube Oman</title>
        <meta name="description" content="Feedback - Cube Oman" />
        <meta name="keywords" content="Feedback US"/>
        <link rel="canonical" href="https://www.cubeoman.com/feedback" />
        </Helmet>
        <Header />
        {/* <Appbar /> */}
        <Menubar />
        <Container fluid className="company-breadcrumb">
          <Row>
            <Col className="breadcrumb-padding" xs={12} md={8}>
              <h1>{t("custLinks.feedback")}</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <div className="blog-breadcrumb-active">{t("custLinks.feedback")}</div>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={12} md={4}>
              <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
            </Col>
          </Row>
        </Container>

        <Container className="feedbackSect">
          <Row>
            <Col xs={12} sm={12} md={6}>
              
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Col className="feedbackInfoCont">
                <h1>{t("custLinks.feedback")}</h1>
                <p className="text-muted">Have a question or just want to say hi? We'd love to hear from you.</p>
                <Form id="feedbackpage">
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridName">

                      <Form.Control onChange={OnChangeFirstName} value={firstname} maxLength={12} type="text" placeholder={t("address.first_name")} />
                      {error.first_name && (
                        <span className="alert-danger danger">{error.first_name}</span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridName">
                      <Form.Control type="text" onChange={OnChangeLastName} value={lastname} placeholder={t("address.last_name")} />
                      {error.last_name && (
                        <span className="alert-danger danger">{error.last_name}</span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("contactInfoField.email")} />
                      {error.email && (
                        <span className="alert-danger danger">{error.email}</span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPhone">
                      <Form.Control type="number" onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder={t("contactInfoField.phone")} />
                      {error.mobile_number && (
                        <span className="alert-danger danger">{error.mobile_number}</span>
                      )}
                    </Form.Group>


                  </Row>
                  <Row className="mb-4">
                    <Form.Group controlId="messageForm.ControlTextarea1">

                      <Form.Control onChange={(e) => setMessage(e.target.value)} placeholder="Message" as="textarea" rows={3} />
                      {error.message && (
                        <span className="alert-danger danger">{error.message}</span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col md={6} xs={6}>

                      <Form.Group as={Col} controlId="formGridPhone">
                        Select Rating:
                        <div
                          onMouseOver={hoverOver}
                          onMouseOut={() => hoverOver(null)}
                          onClick={event => setRating(event.target.getAttribute("star-id"))}
                        >
                          {Array.from({ length: 5 }, (v, i) => (
                            <Star starId={i + 1} marked={selection ? selection > i : rating > i} />

                          ))}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group as={Col} controlId="formGridPhone">

                        <div >Upload Image</div>
                        <input type="file" className='mt-2' onChange={handleOnChange} />
                        {error.image && (
                          <span className="alert-danger danger">{error.image}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>



                  <Button onClick={firstname && lastname && email && message && rating ? handleSubmit : () => Swal.fire("Kindly Enter All Details")} variant="danger" className="feedbackMsgBtn">Submit Now</Button>
                </Form>
              </Col>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  };

export default Feedback;
