import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import PaintEquipmentCategory from '../components/HomepageComponents/PaintEquipmentCategory';
import TopProducts from "../components/HomepageComponents/TopProducts";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
   
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";



async function fetchtopProducts(id) {

  axios.get(BASE_URL + '/api/top-selling-product/' + 69, {
  }).then(({ data }) => {

  
  })
}
const PaintEquipment = () => {
    const { t } = useTranslation();
    const [PaintEquipment, setPaintEquipment] = useState([]);
    const [open, setOpen] = useState(false);
   
    return (
        <div className="overflow">
        <Helmet>
        <title>Paint Equipments Suppliers in Oman for Workshops - Cube Oman</title>
        <meta name="description" content="Trusted paint equipment suppliers in Oman offering a wide range of spray guns, booths, and accessories for automotive workshops." />
        <meta name="keywords" content="Paint Equipments"/>
        <link rel="canonical" href="https://www.cubeoman.com/paint-equipment" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("PaintEquipmentTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("PaintEquipmentTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>

            </Container>
            
            <PaintEquipmentCategory />

            <Container fluid className="testimonials-bg">
            <div  fluid className="container">
      <Col xs={12} md={12} sm={12}>
     
                        <h1>{t("PaintEquipmentTitle")}</h1>
                        <p align="justify">{t("PaintEquipmentDes")}
                        </p>
                        
                        <h4> {t("FeaturesCarSprayBooth")}
                        </h4>
                        <p align="justify"><b>{t("VentilationSystem1")}</b>{t("VentilationSystemDes")}
                        </p>
                        <p align="justify"><b>{t("TemperatureControl1")}</b>{t("TemperatureControlDes")}
                        </p>
                        <p align="justify"><b>{t("Lighting1")}</b>{t("LightingDes")}
                        </p>
                       
                        <p align="justify">{t("PaintLast")}
                        </p>
                        
                       
                    </Col></div></Container>
            <Footer />
        </div>
    );
};

export default PaintEquipment;
