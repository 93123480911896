import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Modal,
    Button,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import CareerCar from "../images/careercarimg.svg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";



const qualifications = [
    {
        id: 1,
        degree: '12th pass'
    },
    {
        id: 2,
        degree: 'Graduate'
    },
    {
        id: 2,
        degree: 'Post-Graduate'
    }
]

const Careers = () => {
    const { t } = useTranslation();
    const [carrier, setCarrier] = useState([]);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [file, setFile] = useState('');
    const [gender, setGender] = useState();
    const [qual, setQual] = useState();
    const [exp, setExp] = useState();
    const [position, setPosition] = useState();
    const [desc, setDesc] = useState()
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem('token');

    const formdata = new FormData();
    formdata.append(["name"], name);
    formdata.append(["position"], position);
    formdata.append(["email"], email);
    formdata.append(["mobile_number"], mobile);
    formdata.append(["gender"], gender);
    formdata.append(["description"], desc);
    formdata.append(["resume"], file);
    formdata.append(["qualification"], qual);
    formdata.append(["experience"], exp);
    const [metaData, setMetaData] = useState([]);
    const handleOnChange = e => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        fetchcarrier();
    }, [])
    const fetchcarrier = async () => {
        await axios.get(BASE_URL + '/api/manage-career').then(({ data }) => {
            setCarrier(data.data)
        })
    }

    function RegisterJob() {
        setErrors('');
        const config = {
            headers: { 'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token }
          }
        axios.post(BASE_URL + '/api/applyforjoob',formdata, config, {
        }).then(({ data }) => {
            setOpen(false)
            if (data.status === true) {
                setErrors('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setErrors(response.data)
            if (response.status === "422") {
                setErrors(response.data.message)
            }
        });
    }

    return (
        <div className="overflow">
         <Helmet>
        <title>Careers - Cube Oman</title>
        <meta name="description" content="Careers - Cube Oman" />
        <meta name="keywords" content="Careers"/>
        <link rel="canonical" href="https://www.cubeoman.com/careers" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("careerTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("careerTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>

            <div className="careerCont">
                <Row>
                    {carrier && carrier.map((career) => (
                        <Col key={career.id} xs={12} sm={12} md={4} className="center">
                            <Card className="text-center career-card mt-2 mb-4">
                                <img className="careerCarImg" src={BASE_URL + '/storage/' + career.image} alt="" width="100px" height="100px" />
                                <Card.Title className="pt-4">{localStorage.getItem("language") == "ar" ? career.title_ar && career.title_ar : career.title && career.title}</Card.Title>
                                <Card.Text className="text-muted" dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? career.content_ar && career.content_ar : career.content && career.content }}></Card.Text>
                                {/* <Link variant="danger" onClick={() => setOpen(true)}>{t("careerApplyBtn")}</Link> */}
                                <a onClick={() => setOpen(true)}>{t("careerApplyBtn")}</a>
                            </Card>
                        </Col>
                    ))}
                    <Modal show={open} onHide={() => setOpen(false)}>
                        <ModalHeader closeButton>
                            Register
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={6} sm={6} xs={6}>
                                    <Form.Control type="text" onChange={(e) => setName(e.target.value)} placeholder={t("contactInfoField.name")} maxLength={12} />

                                </Col>
                                <Col md={6} sm={6} xs={6}>
                                    <Form.Control className="" type="email" onChange={(e) => setEmail(e.target.value)} placeholder={t("contactInfoField.email")} />

                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col md={6} sm={6} xs={6}>
                                    <Form.Control type="number" onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder={t("contactInfoField.phone")} maxLength={12} />

                                </Col>
                                <Col md={6} sm={6} xs={6}>

                                    <Form.Group className="  form-group">

                                        <Form.Select  onClick={(e) => setGender(e.target.value) }>
                                            <option disabled selected>Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>

                                        </Form.Select>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row className="mt-4">

                                <Col md={6}  sm={6} xs={6}>
                                    <Form.Group className="  form-group">

                                        <Form.Select onChange={(e) => setQual(e.target.value)} >
                                            <option disabled selected>Qualifications</option>
                                            {
                                                qualifications && qualifications.map((career) => {
                                                    return (
                                                        <option value={career.degree}>{career.degree}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}  sm={6} xs={6}>
                                    <Form.Group className="  form-group">

                                        <Form.Select  onClick={(e) => setExp(e.target.value) }>
                                            <option disabled selected>Experience</option>
                                            <option value="Fresher">Fresher</option>
                                            <option value="1 - 2 years">1 - 2 years</option>
                                            <option value="2 - 4 years">2 - 4 years</option>
                                            <option value="5 + years"> 5 + years</option>
                                            <option value="10 + years"> 10 + years</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Col md={12}  sm={12} xs={12} className="mt-4">
                                <Form.Group className="  form-group">

                                    <Form.Select onClick={(e) => setPosition(e.target.value)} >
                                        <option disabled selected>Applying for</option>
                                        {
                                            carrier && carrier.map((career) => {
                                                return (
                                                    <option value={career.id}>{career.title}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={12}  sm={12} xs={12} className="mt-4">
                                <Form.Group controlId="messageForm.ControlTextarea1" onChange={(e) => setDesc(e.target.value)}>

                                    <Form.Control placeholder="Message" as="textarea" rows={3} />

                                </Form.Group>
                            </Col>

                            <Col md={12}  sm={12} xs={12} className="mt-2">
                                <Form.Group as={Col} controlId="formGridPhone">

                                    <div >Upload Resume</div>
                                    <input type="file" className='mt-2' onChange={handleOnChange} />
                                    {errors.image && (
                                        <span className="alert-danger danger">{errors.image}</span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col>
                            </Col>
                            <Col className="mt-4 right">
                                <Button onClick={() => RegisterJob()}> Submit</Button>
                            </Col>

                        </ModalBody>
                    </Modal>
                </Row>
            </div>
            <Footer />
        </div>
    );
};

export default Careers;
