import React, { useState, useEffect, createContext, }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, Redirect, Navigate  } from 'react-router-dom'
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ProductDetail from './pages/ProductDetail';
import Blogs from './pages/Blogs';
import BestDeals from './pages/BestDeals';
import BestSellers from './pages/BestSellers';
import LetestProducts from './pages/LetestProducts';
import TopProducts from './pages/TopProducts';
import TyreChanger from './pages/TyreChanger';
import WheelBalancer from './pages/WheelBalancer';
import WheelAlignment from './pages/WheelAlignment';
import PressureWasher from './pages/PressureWasher';
import HydraulicEquipment from './pages/HydraulicEquipment';
import PaintEquipment from './pages/PaintEquipment';
import InjectorEquipment from './pages/InjectorEquipment';
import LightingEquipment from './pages/LightingEquipment';
import LubricationEquipment from './pages/LubricationEquipment';
import ToolsEquipment from './pages/ToolsEquipment';
import VacuumEquipment from './pages/VacuumEquipment';
import ContactUs from './pages/ContactUs';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Feedback from './pages/Feedback';
import Careers from './pages/Careers';
import GetQuotation from './pages/GetQuotation';
import BlogDetail from './pages/BlogDetail';
import "@fontsource/montserrat";
import { BASE_URL } from './config';
import axios from 'axios';
import swal from 'sweetalert';
import GoToTop from './components/GoToTop';
var token;


export const UserContext = createContext()
const App = () => {
  const [item, setItem] = useState(0);
  const [itemservice, setItemService] = useState(0);
  const [carmakeg, setCarMakeg] = useState('');
  const [carmodelg, setCarModelg] = useState('');
  const [fuelg, setFuelg] = useState('');
  const [servicetype, setServiceType] = useState('');
  useEffect(() => {
    var token = localStorage.getItem("token");
  }, [token]);

  useEffect(() => {
    fetchCount();
    FetchItemsAuth();
    fetchCardDetails();
    fetchCardDetailsUnauth();
  }, [])

  let machineId = localStorage.getItem('MachineId');

  const fetchCount = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
        setItem(data.data.cart.item)

      })
    }
  }



  const FetchItemsAuth = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItem(data.data.cart.item)
      })
    }
  }

  const fetchCardDetails = async () => {
    if (token) {
      axios.get(BASE_URL + '/api/get-cart-service', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        setItemService(data.data.cart.item);
        setServiceType(data.data.cart.service_type)
      })
    }
  }
  const fetchCardDetailsUnauth = async () => {
    if (!token) {
      const data = { "device_id": machineId }
      await axios.post(BASE_URL + '/api/get-cart-service-unauth', data).then(({ data }) => {
        setItemService(data.data.cart.item);
        setServiceType(data.data.cart.service_type)
      })
    }
  }

  return (
    <div className='main-wrapper body overflow'>
      <div className='inner-wrapper'>
        <UserContext.Provider value={{ item, setItem, itemservice, setItemService, carmakeg, setCarMakeg, carmodelg, setCarModelg, fuelg, setFuelg, servicetype, setServiceType }}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route path="/best-deals" exact element={<BestDeals />} />
            <Route path="/best-sellers" exact element={<BestSellers />} />
            <Route path="/letest-products" exact element={<LetestProducts />} />
            <Route path="/top-products" exact element={<TopProducts />} />
            <Route path="/product-detail/:slug" exact element={<ProductDetail />} />
            <Route path="/blog" exact element={<Blogs />} />
            <Route path="/tyre-changer" exact element={<TyreChanger />} />
            <Route path="/wheel-balancer" exact element={<WheelBalancer />} />
            <Route path="/wheel-alignment" exact element={<WheelAlignment />} />
            <Route path="/pressure-washer" exact element={<PressureWasher />} />
            <Route path="/hydraulic-equipment" exact element={<HydraulicEquipment />} />
            <Route path="/paint-equipment" exact element={<PaintEquipment />} />
            <Route path="/injector-equipment" exact element={<InjectorEquipment />} />
            <Route path="/lighting-equipment" exact element={<LightingEquipment />} />
            <Route path="/lubrication-equipment" exact element={<LubricationEquipment />} />
            <Route path="/tools-equipment" exact element={<ToolsEquipment />} />
            <Route path="/vacuum-equipment" exact element={<VacuumEquipment />} />
            <Route path="/blog/:slug" exact element={<BlogDetail />} />
            <Route path="/*" exact element={<Navigate to='/' />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/terms-conditions" exact element={<TermsConditions />} />      
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/feedback" exact element={<Feedback />} />
            <Route path="/careers" exact element={<Careers />} />
            <Route path="/get-quotation" exact element={<GetQuotation />} />

          </Routes>
        </UserContext.Provider>
        <GoToTop />
      </div>
    </div>
  );
}

export default App;
