import React, { useState, useEffect, useContext } from 'react';
import ProductBox from '../ProductBox/Productbox';
import SliderwithMenu from '../ProductBox/Slider';
import axios from 'axios';
// import { Col, Modal } from 'react-bootstrap';
import Product from '../../images/product.png';
import { BASE_URL } from '../../config';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Col, Nav, Container, Card, Button, Row, Modal, Form, ModalHeader, ModalBody } from 'react-bootstrap';
import { UserContext } from '../../App';

function TopProducts() {

  useEffect(() => {
    fetchtopProducts(0);
    getMachineId();
    fetchCarMake();
  }, []);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState(localStorage.getItem("carmake"));
  const [selectedCarModel, setSelectedCarModel] = useState(localStorage.getItem("carmodel"));
  const [make, setMake] = useState(false);
  const [modal, setModal] = useState(false);
  const [state, setState] = useState();
  const [stateId, setIdState] = useState();
  const [carData, setCarData] = useState(false);
  const [slugId, setSlugId] = useState();

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');

  const [products, setProducts] = useState([]);
  const { item, setItem } = useContext(UserContext);
  async function fetchtopProducts(id) {

    axios.get(BASE_URL + '/api/top-selling-product/' + id, {
    }).then(({ data }) => {

      setProducts(data.data);

    })
  }

  const fetchCarMake = async () => {
    await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {
      setCarMake(data.data)
    })
  }
  async function fetchCarModel(data) {
    const car = new String(data)

    const carMake = car.split("_")
    const CarMakeId = carMake[0]
    setSelectedCarMake(localStorage.setItem('cardata', carMake[1]));
    await axios.get(BASE_URL + '/api/car-model/' + CarMakeId).then(({ data }) => {
      setCarModel(data.data)
    }).catch(({ response }) => {

    })
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }

  }

  function handleSubmitOffline(id, option) {
    setOpen(true)
    setShow(false)
    const data = { "device_id": machineId, "product_id": id, "product_option_id": option }
    axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
      setModalData(data)
      setItem(data.item);
      if (data.status === "success") {
        setError('')

        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/shopping-cart")
        document.getElementById("otherService").reset();

      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  function handleSubmit(id, option) {
    setOpen(true)
    setShow(false)
    setError('');
    const data = { 'product_id': id, 'product_option_id': option }
    axios.post(BASE_URL + '/api/store-cart', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setItem(data.item);
      setModalData(data)
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })

        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  return (
    <SliderwithMenu name={t("productTab.topSelling")} boldname={t("productTab.prod")} product={fetchtopProducts} categories={products}>
      {products.map(i => (
        <Col md={3} sm={4} xs={6}>
          <ProductBox
            name={localStorage.getItem('language') == 'ar' ? i.name_ar && i.name_ar : i.name && i.name}
            img={i.image}
            BtnCart={t("productTab.cartBtn")}
            
            productDetail={localStorage.getItem('cardata') && localStorage.getItem('carmodeldata') ? () => navigate("/product-detail/" + i.slug) : () => {navigate("/product-detail/" + i.slug)}}
          />
        </Col>
      ))}
      <Modal show={show} onHide={() => setShow(false)} className="body" size="md"
        aria-labelledby="contained-modal-title-vcenter" centered

      ><ModalBody>
            <div className='center mt-2'>{modalData ? modalData.meassge && modalData.meassge == "Already Added To Cart" ?
            <div className='text-danger'>You have already added this product in the cart, do you want to add more? <span className="pointer text-blue" onClick={() => navigate('/shopping-cart')} >Yes</span> </div>
            :
            modalData.meassge : ''}</div>
              
            </ModalBody>
      </Modal>
      <Modal show={carData} onHide={() => setCarData(false)} className="body" size="md"
            aria-labelledby="contained-modal-title-vcenter" centered

          >
            
            <ModalBody>
            <div className='center mt-2'>{modalData ? modalData.meassge && modalData.meassge == "Already Added To Cart" ?
            <div className='text-danger'>You have already added this product in the cart, do you want to add more? <span className="pointer text-blue" onClick={() => navigate('/shopping-cart')} >Yes</span> </div>
            :
            modalData.meassge : ''}</div>
              
            </ModalBody>
          </Modal>
      
    </SliderwithMenu>

  )
}

export default TopProducts;