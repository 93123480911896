import React, {useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Menubar from "../components/Navbar/Menubar_new";

const PrivacyPolicy = () => {
  const {t} = useTranslation();
  const [policy,setpolicy] = useState('');
  useEffect(()=>{
    fetchpolicy();
  },[]);

  const fetchpolicy = async()=>{
    await axios.get(BASE_URL+'/api/policy/privacy_policy').then(({data})=>{
      setpolicy(data.data);
    });
  }
    return (
        <div className="overflow">
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="p50" xs={12} md={8}>
                        <h1>{localStorage.getItem("language") == "ar" ? policy.title_ar && policy.title_ar : policy.title && policy.title }</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active >
                            <div className="blog-breadcrumb-active">  {localStorage.getItem("language") == "ar" ? policy.title_ar && policy.title_ar : policy.title && policy.title } </div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>
             <div className="policy-content">
        <div className="pt-4 pb-2">
          <h2>{localStorage.getItem("language") == "ar" ? policy.title_ar && policy.title_ar :  policy.title && policy.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? policy.content_ar && policy.content_ar : policy.content && policy.content  }}>
          </p>
        </div>
      </div>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
