import React from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import style from '../../styles/itemBox.module.css';
import Rating from '../../images/Icons/rating.png';
import Cart from '../../images/Icons/Cart_icon.png';
import { BASE_URL } from '../../config';

function ItemBox({ img, actualPrice, discountPrice, name, discount_perc, BtnCart, productDetail, ViewMore }) {
    return (
        <>
        <div className={`card featured-card-height ${style.card}`}>
            <div className={`${style.innerCard}`}>
                <div className={style.outerCard}>
                    
                    <div className={`image-section ${style.imgContainer}`}>
                        <img src={BASE_URL + '/storage/' + img} className={`product-img ${style.img}`} onClick={productDetail} />
                    </div>
                    <h4 className={style.name}>{name}</h4>
                    <div className={`flex center ${style.rating}`}>
                        <img src={Rating} alt="" className={style.margin} />
                        <img src={Rating} alt="" className={style.margin} />
                        <img src={Rating} alt="" className={style.margin} />
                        <img src={Rating} alt="" className={style.margin} />
                        <img src={Rating} alt="" className={style.margin} />
                    </div>
                    
                    
                    <div className="mt-2 center">
                        <Button variant="dark" onClick={productDetail}><img src={Cart} className={style.cartIcon} />{BtnCart}</Button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default ItemBox;