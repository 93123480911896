import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    Container,
    Button,
    Row,
    Col,
    Form,
    Card,
    Nav,
    Accordion,
    Carousel,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../config";
import style from '../../styles/home.module.css';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import RightArrow from '../../images/Icons/RightArrow.png';
import LeftArrow from '../../images/Icons/LeftArrow.png';
import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";






const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [model, setModel] = useState(false);
    const [menufacturer, setMenufacturer] = useState(false);
    const [subject, setSubject] = useState('');
    const [active01, setActive01] = useState(true);
    const [active02, setActive02] = useState();
    const [active03, setActive03] = useState();
    const [active04, setActive04] = useState();
    const [fuel, setFuel] = useState(false);
    const [slider, setSlider] = useState([]);
    const [carmake, setCarMake] = useState([]);
    const [carmodel, setCarModel] = useState([]);
    const [selectedCarMake, setSelectedCarMake] = useState();
    const [selectedCarModel, setSelectedCarModel] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [selectedFuel, setSelectedFuel] = useState();
    const value = selectedCarMake ? [selectedCarMake, selectedCarModel, selectedFuel] : [t("menu.serviceDetail.ac.selectMake") + " , " + t("appointDropdown.model") + "  &  " + t("appointDropdown.fuel")];

    const length = slider.length - 1

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const onPrevClick = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else if (index === 0) setIndex(length);
    };
    const onNextClick = () => {
        if (index === length) {
            setIndex(0);
        } else if (index === 0 || index > 0) setIndex(index + 1);
    };
    const handle = async (id) => {
        setSelectedCarModel(null)
        setSelectedFuel(null)
        setMenufacturer(false)
        setModel(true)

        await axios.get(BASE_URL + '/api/car-model/' + id, {}).then(({ data }) => {

            setCarModel(data.data);

        })

    }

    const handleFuel = () => {
        setModel(false)
        setFuel(true)
    }


    useEffect(() => {
        getSliderData();
        fetchCarMake();

    }, [])

    const getSliderData = async () => {
        await axios.get(BASE_URL + '/api/sliders').then(({ data }) => {
            setSlider(data.data);

        })
    }

    const fetchCarMake = async () => {

        await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {

            setCarMake(data.data);

        })
    }

    function handleSubmit(e) {
        e.preventDefault();
        setError('');
        axios.post(BASE_URL + '/api/contact-us', { 'name': name, 'email': email, 'mobile_number': mobile, 'message': message, 'subject': subject }).then(({ data }) => {
          if (data.status_code === 200) {
            setError('')
            Swal.fire({
              text: data.message,
              icon: "success"
            })
            document.getElementById("contactpage").reset();
          }
        }).catch(({ response }) => {
    
          if (response.status == '422') {
            setError(response.data.message)
          }
        });
      }
    
    

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={8}>
                    <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        indicators={false}
                        controls={false}

                        className={style.automativeContainer}
                    >

                        {slider.map((slider) => (

                            <Carousel.Item>
                                <Row>
                                    <Col xs={12} md={7}>
                                        <br />
                                        <div className={style.automotiveHeading}>
                                            {localStorage.getItem("language") == "ar" ? slider.title_ar && slider.title_ar : slider.title && slider.title}
                                            
                                        </div>
                                        <div className={`text-muted ${style.autoText1} ${style.mobileSize}`} dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? slider.sub_title_ar && slider.sub_title_ar : slider.sub_title && slider.sub_title }}></div>
                                        <div className={`text-muted ${style.autoText1} ${style.mobileSize}`} dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? slider.content_ar && slider.content_ar : slider.content && slider.content }}></div>
                                        <div className={style.mobileSizeDisplay}>
                                            <div className={`text-muted ${style.autoText1}`} dangerouslySetInnerHTML={{ __html: localStorage.getItem("language") == "ar" ? slider.content_ar && slider.content_ar : slider.content && slider.content.length > 150 ? slider.content.substring(0, 100) : slider.content }}>
                                            </div>
                                            {slider.content.length > 150 ?
                                                <a className="mt-2"> read more</a> : ""}
                                        </div>
                                        <br />
                                        <div className={style.mobileSize}>
                                            <Button variant="danger" className={`${style.viewMoreBtn}`} target="_blank " href={slider.button_link}>
                                                {t("viewMoreBtn")}
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={5} className="center">
                                        <img src={BASE_URL + '/storage/' + slider.image} alt="banner" className={style.carSpringImg} />
                                    </Col>
                                </Row>
                                <Col md={12} className="mt-4 center">
                                    <Col className="flex center">  <img src={LeftArrow} className={style.LeftArrowStyle} onClick={onPrevClick} /> <img src={RightArrow} className={style.RightArrowStyle} onClick={onNextClick} /></Col>
                                    <Col  >  </Col>
                                </Col>
                            </Carousel.Item>

                        ))}

                    </Carousel>
                </Col>
                <Col xs={12} sm={12} md={4} >
                  
                    <div className={style.automotiveContainerForm } >
                        <div className={style.bookApt}>
                            <div className={style.appointFont}>{t("needsomehelp?")}</div>
                        </div>
                        <div className={style.form}>
                            <Form id="contactpage">
                            <Row className={`mb-2 mt-3`}>
                                <Form.Group as={Col} controlId="formGridName">

                                    <Form.Control type="text" onChange={(e) => setName(e.target.value)} placeholder={t("contactInfoField.name")} />
                                    {error.name && (
                                        <span className="alert-danger danger">{error.name}</span>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridPhone">

                                    <Form.Control type="number" value={mobile} onChange={(e) => { e.target.value = (Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)); setMobile(e.target.value) }} placeholder={t("contactInfoField.phone")} />
                                    {error.mobile_number && (
                                        <span className="alert-danger danger">{error.mobile_number}</span>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail">

                                    <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} placeholder={t("contactInfoField.email")} />
                                    {error.email && (
                                        <span className="alert-danger danger">{error.email}</span>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridSubject">

                                    <Form.Control type="subject" onChange={(e) => setSubject(e.target.value)} placeholder={t("contactInfoField.subject")} />
                                    {error.subject && (
                                        <span className="alert-danger danger">{error.subject}</span>
                                    )}
                                </Form.Group>
                            </Row>
                            
                            
                            <Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridMessage">

                                    <Form.Control onChange={(e) => setMessage(e.target.value)} value={message} placeholder={t("contactInfoField.msg")} as="textarea" rows={4} />
                                    {error.description && (
                                        <span className="alert-danger danger">{error.message}</span>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row className={`mb-3 mt-0`}>
                            <Button variant="danger" onClick={name && email && mobile && subject && message ? handleSubmit : () => Swal.fire("Kindly Enter All Details")} className={`mt-3 ${style.searchBtn}`} >{t("submitnow")}</Button>
                            </Row>
                            </Form>
                        </div>
                    </div>
                </Col>



                
                
            </Row>
        </>
    );
};

export default Home;
