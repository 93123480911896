import React, { useEffect, useState, createContext, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Cookies from "js-cookie";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Nav, Col } from "react-bootstrap";
import LocIcon from "../../images/locationicon.svg";
import axios from "axios";
import { BASE_URL } from "../../config";
// import Badge from '@mui/material/Badge';
import { useCart } from "react-use-cart";
import style from '../../styles/header.module.css';
import WhiteCallIcon from '../../images/Icons/phone-white.png';
import Whatsapp from '../../images/Icons/Whatsapp.svg';
import Mail from '../../images/Icons/mail-white.svg';
import { Badge, Box, InputBase } from '@mui/material';
import { UserContext } from '../../App'
import { Helmet } from "react-helmet";
import Facebook from '../../images/fbround.svg';
import Twitter from '../../images/twitterround.svg';
import LinkedIn from '../../images/linkedinround.svg';
import Insta from '../../images/Icons/insta-wh.svg';


const shapeStyles = {};
const shapeCircleStyles = { borderRadius: '50%' };

export const Icons = ({ children }) => {
    return (
        <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }}>
            <div >
                {children}
            </div>
        </Box>
    )
}

const Header = () => {
    const languages = [
        { name: "English", code: "en" },
        { name: "Arabic", code: "ar", dir: "rtl" },
    ]


    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        items,
        updateItemQuantity,
        removeItem,
    } = useCart();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    // const currentLocale = Cookies.get("i18next") || "en";
    const currentLocale = "en";
    const [language, setLanguage] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : currentLocale);
    const [langs, setlang] = useState('');
    const [social, setsocial] = useState('');
    const [setting, setsetting] = useState('');
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [cartCount, setCartCount] = useState([]);
    const token = localStorage.getItem('token');
    let machineId = localStorage.getItem('MachineId');
    const user = JSON.parse(localStorage.getItem("userdata"))
    const [profile, setprofile] = React.useState(10);
    const handleChange = (event) => {
        setprofile(event.target.value);
    };

    const count = cartCount.length;
    // const AuthCount = cartAuthCount.length;
    const { item, setItem, itemservice, setItemService } = useContext(UserContext);


    const handleChangeLocale = (e) => {
        const lang = e.target.value;
        setLanguage(lang);
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
    };


    const location = useLocation();

    const currentLangObj = languages.find((lang) => lang.code === currentLocale);

    useEffect(() => {
        document.title = t("app_title");
        fetchsocialsetting();
        fetchlanguage();
        fetchHeaderData();
        fetchCount();
        FetchItemsAuth();
        getMachineId();
        fetchCardDetails();
        fetchCardDetailsUnauth();
    }, [pathname])

    // useEffect(() => {
    //     setInterval(() => { fetchCount(); FetchItemsAuth() }, 18000)
    // }, []);

    const fetchsocialsetting = async () => {
        await axios.get(BASE_URL + '/api/social-settings').then(({ data }) => {
            setsocial(data.data);
        })
    }
    function fetchlanguage() {
        axios.get(BASE_URL + '/api/site-settings').then(({ data }) => {
            setlang(data.data.lan_ar);
        })
    }

    function fetchHeaderData() {
        axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
            setsetting(data.data);
        })
    }

    function getMachineId() {

        let machineId = localStorage.getItem('MachineId');

        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }


    }

    const fetchCount = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-cart-unauth', data).then(({ data }) => {
                setItem(data.data.cart.item)

            })
        }
    }


    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/')
    }


    function handleAdd() {
        setShowAdd(true);
        // setShowEdit(false);
        // setEditAddress({});
        // setShowLogin(true);

    }

    const FetchItemsAuth = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-cart', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setItem(data.data.cart.item)
            })
        }
    }

    const fetchCardDetails = async () => {
        if (token) {
            axios.get(BASE_URL + '/api/get-cart-service', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setItemService(data.data.cart.item);
            })
        }
    }
    const fetchCardDetailsUnauth = async () => {
        if (!token) {
            const data = { "device_id": machineId }
            await axios.post(BASE_URL + '/api/get-cart-service-unauth', data).then(({ data }) => {
                setItemService(data.data.cart.item);
            })
        }
    }

    return (
        <>
            <Helmet>
                <link rel="icon" href={BASE_URL + '/storage/' + setting.favicon} />
            </Helmet>
            <div className={style.desktopView}>
                <div>
                    {setting.show_in_header_coupon_code == 'on' ?
                        <div className={` ${style.marginLeftRight5} ${style.couponFont} ${style.mtb5}  pointer center`} dangerouslySetInnerHTML={{ __html: setting.coupon_code && setting.coupon_code }}></div>
                        : ""}
                </div>
                <div className={style.headerContainer}>
                    <div className={style.leftContainer}>

                        {langs == '1' ?
                            <>
                                <div className={style.marginLeftRight5}>
                                    <div className="flex">
                                        <div className="whiteText selectLanguage">Select Language</div>

                                        <select className={style.languageDropdown} defaultValue={localStorage.getItem("language")} onChange={handleChangeLocale}>

                                            {languages.map((i) => (
                                                <option key={i.code} value={i.code}>{i.name}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className={style.border}></div>
                            </>
                            : ''}
                      
                        {setting.show_in_header_email == 'on' ?
                            <>

                                <div className={`${style.marginTop} ${style.marginLeftRight5} pointer`}>
                                    <a className={style.font} href={"mailto:" + setting.email}>
                                        <img
                                            alt=""
                                            src={Mail} pathname
                                            width="28"
                                            height="25"
                                            className="d-inline-block align-top"
                                        />{" "}
                                        {setting.email && setting.email}
                                    </a>
                                </div> </> : ''}

                        <div className={style.border}></div>

                        <div className={`${style.marginTop} ${style.marginLeftRight5}`}>
                                    <a className={style.font} href={"tel:" + setting.tollfree_number}>
                                        <img
                                            alt=""
                                            src={WhiteCallIcon}
                                            width="28"
                                            height="25"
                                            className="d-inline-block align-top"
                                        />{" "}
                                        {setting.tollfree_number && setting.tollfree_number}
                                        
                                    </a>

                                </div>
                       
                        
                    </div> 
                  
                
                
                    <div className={style.rightContainer}>
                       
                        
                       
                       
                       
                                <div className={style.lang}>
                                    <select className={style.languageDropdown} defaultValue={localStorage.getItem("language")} onChange={handleChangeLocale} >
                                        {languages.map((i) => (
                                            <option key={i.code} value={i.code}>{i.name}</option>
                                        ))}
                                    </select>
                                </div>
                                
                        
                       
                        <div className={style.marginLeftRight5}>
                        <div className="anchor-regular" >
                        
                  {social.show_in_footer_fb == 'on' ? 
                    <Nav.Link href={social.fb_name} target={"_blank"} rel={"nofollow"} > 
                      <img className={style.marginRight2} src={Facebook} alt="" height="32px" width="32px" />
                    </Nav.Link> : ""}
                   
                  {social.show_in_footer_twit == 'on' ?
                    <Nav.Link href={social.twit_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.marginRight2} src={Twitter} alt="" height="32px" width="32px" />
                    </Nav.Link>
                    : ""}
                     
                  {social.show_in_footer_linkedin == 'on' ?
                    <Nav.Link href={social.linkedin_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.marginRight2} src={LinkedIn} alt="" height="32px" width="32px" />
                    </Nav.Link>
                    : " "}
                  {social.show_in_footer_insta == 'on' ?
                    <Nav.Link href={social.insta_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.marginRight2} src={Insta} alt="" height="32px" width="32px" />
                    </Nav.Link>
                    : ""}
                
                </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div className={style.mobileView}>
                <Col xs={12}>
                    <div>
                        {setting.show_in_header_coupon_code == 'on' ?
                            <div className={` ${style.marginLeftRight5} ${style.couponFont} ${style.mtb5} pointer center`} dangerouslySetInnerHTML={{ __html: setting.coupon_code && setting.coupon_code }}></div>
                            : ""}
                    </div>
                </Col>
                <Col xs={12}>
                    <div className={style.headerContainer} >
                        {langs == '1' ?
                            <>
                                <div className={style.lang}>
                                    <select className={style.languageDropdown} defaultValue={localStorage.getItem("language")} onChange={handleChangeLocale} >
                                        {languages.map((i) => (
                                            <option key={i.code} value={i.code}>{i.name}</option>
                                        ))}
                                    </select>
                                </div>
                               <div className={style.whiteborder}></div> </> : ''}
                       
							<div className={`${style.marginTop} ${style.marginLeftRight5} pointer`}>
                            <a onClick={() => navigate("/find-location")} className={style.font}>
                                <img
                                    alt=""
                                    src={LocIcon}
                                    width="25"
                                    height="25"
                                    className="d-inline-block align-top"
                                />{" "}
                                {t("menu.locator")}
                            </a>
                        </div>
                        <div className={style.lang}>
                                    <select className={style.languageDropdown} defaultValue={localStorage.getItem("language")} onChange={handleChangeLocale} >
                                        {languages.map((i) => (
                                            <option key={i.code} value={i.code}>{i.name}</option>
                                        ))}
                                    </select>
                                </div>
						
                        
                    </div>
                   
                </Col>
            </div>
        </>
    );
};

export default Header;
