import { NavLink, Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Form, InputGroup, Button, Nav, Col } from 'react-bootstrap'
import CallIcon from '../../images/yellowcall.svg';
import TollFreeIcon from '../../images/supportlineicon.svg';
import MailIcon from '../../images/yellowmail.svg';
import Facebook from '../../images/fbround.svg';
import Twitter from '../../images/twitterround.svg';
import LinkedIn from '../../images/linkedinround.svg';
import OPALGCC from '../../images/OPALGCC.png';
import OPALTE from '../../images/OPALTE.png';
import PTACOMAN from '../../images/PTACOMAN.png';
import OPALTBA from '../../images/OPALTBA.png';
import AUTOWORLDOMAN from '../../images/AUTOWORLDOMAN.png';
import ELGIOMAN from '../../images/ELGIOMAN.png';
import axios from "axios";
import { BASE_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import style from '../../styles/footer.module.css';
import Insta from '../../images/Icons/insta-wh.svg';
import Whatsapp from '../../images/Icons/WhatsappFooter.svg'

const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [social, setsocial] = useState('');
  const [email, setemail] = useState('');
  const { pathname } = useLocation();
  const [category, setCategory] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchfooterdata = async () => {

      await axios.get(BASE_URL + '/api/social-settings').then(({ data }) => {
        setsocial(data.data);
      })

      await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
        setCategory(data.data)

      })
      await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
        setFooterData(data.data);
      })
    }
    fetchfooterdata();
  }, [pathname]);



  function submitSubscribe() {
    axios.post(BASE_URL + '/api/subscribers', { email: email }).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      });
      document.getElementById("subscribe").reset();
    }).catch(({ response }) => {
      setErrors(response.data.message.email)
      if (response.status === 422) {
        Swal.fire({
          text: response.data.message.email,
          icon: "error"
        })
      }
    })
  }

  const OnEmailValidation = e => {
    const { value } = e.target;

    const re = /^[A-Za-z@.1-9]+$/;
    if (value === "" || re.test(value)) {
      setemail(value);
    }
  }


  return (
    <>
      <div className="top-footer">
        <div className="container-fluid">
          <div className="row" >
            {category && category.map((data) => (
              <Col md={3} xs={6}>
                <h5 className={style.Categ}>{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}</h5>
              </Col>

            ))}
          </div>
        </div>
        <br />
        <br />

        <div className={style.border}></div>
        <br />
        <br />
        <div className="container-fluid">
          <div className="row">
            <Col md={3} sm={6} xs={12} className={`${style.ipadSize} ${style.FooterLogo}`}>
              <div className="foot-logo">
                <Nav.Link href='/'>
                  <img
                    src={BASE_URL + '/storage/' + footerData.footer_logo}
                    alt="logo"
                    className={style.logo}
                    height="138px"
                    width="250px"

                  />
                </Nav.Link>
              </div>
              <div className="footer-para mt-2">
                <p className={`left ${style.des}`}>
                  {footerData.short_description && footerData.short_description}
                </p>
              </div>

            </Col>
            <Col className={`col-md-3 col-sm-6 col-xs-6 ${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`}>
              <h5 className={`left ${style.white}`} >{t("companyprofile")}</h5>
              <ul className="foot-menu-list">
                <li>
                  <Nav.Link href="/" className="footer-navlinks">{t("menu.home")}</Nav.Link>
                </li>
                <li className="mt-2">
                  <Nav.Link href="/blog" className="footer-navlinks">{t("menu.blog")}</Nav.Link>
                </li>
                <li className="mt-2">
                  <Nav.Link href="/careers" className="footer-navlinks">{t("menu.career")}</Nav.Link>
                </li>
                
                <li className="mt-2">
                  <Nav.Link href="/contact-us" className="footer-navlinks">{t("contact")}</Nav.Link>
                </li>
                
              </ul>
            </Col>
            <Col md={1} xs={12} className={`col-md-3 col-sm-6 col-xs-6 ${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`} >
              <h5 className={` left ${style.white}`} >{t("customer")}</h5>
              <ul className="foot-menu-list">
                <li>
                  <Nav.Link href="/best-sellers" className="footer-navlinks">{t("bestsellersTitle")}</Nav.Link>
                </li>
                <li className="mt-2">
                  <Nav.Link href="/letest-products" className="footer-navlinks">{t("LetestProductsTitle")}</Nav.Link>
                </li>
                <li className="mt-2">
                  <Nav.Link href="/top-products" className="footer-navlinks">{t("TopProductsTitle")}</Nav.Link>
                </li>
                <li className="mt-2">
                  <Nav.Link href="/best-deals" className="footer-navlinks">{t("BestDealsTitle")}</Nav.Link>
                </li>

                
               
              </ul>
            </Col>
            <Col md={3} sm={6} xs={12} className={`${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`}>
              <h5 className={` ${style.white}`}>{t("touch")}</h5>

              
              {footerData.show_in_footer_tollfree_number == 'on' ?
                <div className="mt-2">
                  <Nav.Link className={style.white} href={"tel:" + footerData.tollfree_number}>
                    <img src={TollFreeIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.ipadSize}`}>{footerData.tollfree_number && footerData.tollfree_number}</span>
                  </Nav.Link>
                </div>
                : ""}
                {footerData.show_in_footer_number_1 == 'on' ?
                <div className="mt-0">
                  <Nav.Link className={style.white} href={"tel:" + footerData.number_1}>
                    <img src={CallIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.number_1 && footerData.number_1}</span>
                  </Nav.Link>
                </div>
                : ""}
                {footerData.show_in_footer_number_2 == 'on' ?
                <div className="mt-0">
                  <Nav.Link className={style.white} href={"tel:" + footerData.number_2}>
                    <img src={CallIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.number_2 && footerData.number_2}</span>
                  </Nav.Link>
                </div>
                : ""}
                {footerData.show_in_footer_number_3 == 'on' ?
                <div className="mt-0">
                  <Nav.Link className={style.white} href={"tel:" + footerData.number_3}>
                    <img src={CallIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.number_3 && footerData.number_3}</span>
                  </Nav.Link>
                </div>
                : ""}
                {footerData.show_in_footer_number_4 == 'on' ?
                <div className="mt-0">
                  <Nav.Link className={style.white} href={"tel:" + footerData.number_4}>
                    <img src={CallIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.number_4 && footerData.number_4}</span>
                  </Nav.Link>
                </div>
                : ""}
              {footerData.show_in_footer_whatsapp_number == 'on' ?
                <div className="mt-2">
                  <Nav.Link className={style.white} href={"tel:" + footerData.whatsapp_number}>
                    <img src={Whatsapp} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.whatsapp_number && footerData.whatsapp_number}</span>
                  </Nav.Link>
                </div>
                : ""}
                {footerData.show_in_footer_email == 'on' ?
                <div className="mt-2">
                  <Nav.Link className={style.white} href={"mailto:" + footerData.email}>
                    <img src={MailIcon} alt="" className={style.icon} />
                    <span className={`${style.white} ${style.iconName}`}>{footerData.email}</span>
                  </Nav.Link>
                </div>
                : ""}
                
              
              
            </Col>
            

          </div>
        </div>
      </div>
      
      <div className="container-fluid"><div className="row">
      <Col md={4} xs={12} className={`col-md-3 col-sm-6 col-xs-6 ${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`} >
        <div className="text-center mt-2"> {t("copyright")} </div></Col>

        <Col md={5} xs={12} className={`col-md-3 col-sm-6 col-xs-6 ${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`} >
        <div className="anchor-regular center mt-1">
                  
                    <Nav.Link href={"https://www.opalgcc.com/"} target={"_blank"}>
                      <img className={style.social} src={OPALGCC} alt="" height="40px" width="80px" />
                    </Nav.Link> 
                    <Nav.Link href={"https://www.opalte.com/"} target={"_blank"}>
                      <img className={style.social} src={OPALTE} alt="" height="40px" width="80px" />
                    </Nav.Link>
                    <Nav.Link href={"https://opaltba.com/"} target={"_blank"}>
                      <img className={style.social} src={OPALTBA} alt="" height="40px" width="80px" />
                    </Nav.Link>
                    <Nav.Link href={"https://precisiontunegcc.com/"} target={"_blank"}>
                      <img className={style.social} src={PTACOMAN} alt="" height="40px" width="80px" />
                    </Nav.Link>
                    
                    <Nav.Link href={"https://www.elgioman.com/"} target={"_blank"}>
                      <img className={style.social} src={ELGIOMAN} alt="" height="45px" width="70px" />
                    </Nav.Link>

                </div></Col>
        <Col md={3} xs={12} className={`col-md-3 col-sm-6 col-xs-6 ${style.containerPadding} ${style.menuPadding} ${style.ipadSize}`} >
        <div className="anchor-regular center">
                  {social.show_in_footer_fb == 'on' ?
                    <Nav.Link href={social.fb_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.social} src={Facebook} alt="" height="45px" width="45px" />
                    </Nav.Link> : ""}
                  {social.show_in_footer_twit == 'on' ?
                    <Nav.Link href={social.twit_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.social} src={Twitter} alt="" height="45px" width="45px" />
                    </Nav.Link>
                    : ""}
                  {social.show_in_footer_linkedin == 'on' ?
                    <Nav.Link href={social.linkedin_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.social} src={LinkedIn} alt="" height="45px" width="45px" />
                    </Nav.Link>
                    : " "}
                  {social.show_in_footer_insta == 'on' ?
                    <Nav.Link href={social.insta_name} target={"_blank"} rel={"nofollow"}>
                      <img className={style.social} src={Insta} alt="" height="45px" width="45px" />
                    </Nav.Link>
                    : ""}
                   
                 
                </div></Col>
      </div>
      </div>
                
                
              
    </>
  );
};
export default Footer;
