import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import HydraulicEquipmentCategory from '../components/HomepageComponents/HydraulicEquipmentCategory';
import TopProducts from "../components/HomepageComponents/TopProducts";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Modal,
    Button,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";



async function fetchtopProducts(id) {

  axios.get(BASE_URL + '/api/top-selling-product/' + 69, {
  }).then(({ data }) => {

  
  })
}
const HydraulicEquipment = () => {
    const { t } = useTranslation();
    const [HydraulicEquipment, setHydraulicEquipment] = useState([]);
    const [open, setOpen] = useState(false);
   
    return (
        <div className="overflow">
        <Helmet>
        <title>Hydraulic Equipment Suppliers in Oman for Workshops - Cube Oman</title>
        <meta name="description" content="Reliable hydraulic equipment suppliers in Oman providing high-quality machinery and tools for automotive workshops, enhancing performance and efficiency in operations." />
        <meta name="keywords" content="Hydraulic Equipment"/>
        <link rel="canonical" href="https://www.cubeoman.com/hydraulic-equipment" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row> 
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("HydraulicEquipmentTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("HydraulicEquipmentTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>

            </Container>           
            <HydraulicEquipmentCategory />
            
            <Container fluid className="testimonials-bg">
            <div  fluid className="container">
      <Col xs={12} md={12} sm={12}>
     
                        <h1>{t("HydraulicEquipmentTitle")}</h1>
                        <p align="justify">{t("HydraulicEquipmentDescription1")} 
                        </p>
                        
                        <h4> {t("HydraulicJacks1")} 
                        </h4>
                        <p align="justify"><b>{t("BottleJacks1")}</b> {t("BottleJacksDes")}
                        </p>
                        <p align="justify"><b>{t("FloorJacks1")}</b> {t("FloorJacksDes")}
                        </p>
                        <p align="justify"><b>{t("ScissorJacks1")}</b> {t("ScissorJacksDes")}
                        </p>
                        <h4> {t("HydraulicTorqueWrenches1")} 
                        </h4>
                        <p align="justify">{t("HydraulicTorqueWrenchesDes")}
                        </p>
                        <h4> {t("HydraulicCrimpingTools1")} 
                        </h4>
                        <p align="justify">{t("HydraulicCrimpingToolsDes")}
                        </p>
                        
                        <h4> {t("HydraulicCylinders1")}
                        </h4>
                        <p align="justify"><b>{t("SingleActingCylinders1")}</b> {t("SingleActingCylindersDes")}
                        </p>
                        <p align="justify"><b>{t("DoubleActingCylinders1")}</b> {t("DoubleActingCylindersDes")}
                        </p>
                        <h4> {t("HydraulicPresses1")}
                        </h4>
                        <p align="justify">{t("HydraulicPressesDes")}
                        </p>
                        <p align="justify"><b>{t("HFramePresses1")}</b>{t("HFramePressesDes")}
                        </p>
                        <p align="justify"><b>{t("CFramePresses1")}</b>{t("CFramePressesDes")}
                        </p>
                        <h4> {t("HydraulicRescueTools1")}
                        </h4>
                        <p align="justify">{t("HydraulicRescueToolsDes")}
                        </p>
                        <h4> {t("HydraulicPullers1")}
                        </h4>
                        <p align="justify">{t("HydraulicPullersDes")}
                        </p>
                        <h4> {t("HydraulicLifts1")}
                        </h4>
                        
                        <p align="justify"><b>{t("TwoPostLifts1")}</b> {t("TwoPostLiftsDes")}
                        </p>
                        <p align="justify"><b>{t("FourPostLifts1")}</b> {t("FourPostLiftsDes")}
                        </p>
                        <p align="justify"><b>{t("ScissorLifts1")}</b> {t("ScissorLiftsDes")}
                        </p>
                        <h4> {t("HydraulicPipeBenders1")}
                        </h4>
                        <p align="justify">{t("HydraulicPipeBendersDes")}
                        </p>
                        <h4> {t("HydraulicPumps1")}
                        </h4>
                        
                        <p align="justify"><b>{t("HandPumps1")}</b> {t("HandPumpsDes")}
                        </p>
                        <p align="justify"><b>{t("ElectricPumps1")}</b> {t("ElectricPumpsDes")}
                        </p>
                        <p align="justify">{t("HydraulicLast")}
                        </p>
                        
                       
                    </Col></div></Container>
            <Footer />
        </div>
    );
};

export default HydraulicEquipment;
