import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import WheelAlignmentCategory from '../components/HomepageComponents/WheelAlignmentCategory';
import TopProducts from "../components/HomepageComponents/TopProducts";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Modal,
    Button,
    ModalHeader,
    ModalBody
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
import Swal from "sweetalert2";



async function fetchtopProducts(id) {

  axios.get(BASE_URL + '/api/top-selling-product/' + 69, {
  }).then(({ data }) => {

  
  })
}
const WheelAlignment = () => {
    const { t } = useTranslation();
    const [WheelAlignment, setWheelAlignment] = useState([]);
    const [open, setOpen] = useState(false);
   
    return (
        <div className="overflow">
        <Helmet>
        <title>Wheel Alignment Suppliers in Oman for Workshops - Cube Oman</title>
        <meta name="description" content="Trusted wheel alignment suppliers in Oman providing advanced equipment for accurate vehicle alignment and improved performance in automotive workshops." />
        <meta name="keywords" content="Wheel Alignment"/>
        <link rel="canonical" href="https://www.cubeoman.com/wheel-alignment" />
        </Helmet>
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding" xs={12} md={8}>
                        <h1>{t("WheelAlignmentTitle")}</h1>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <div className="breadcrumb-active">{t("WheelAlignmentTitle")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>
  
            </Container>
            
            <WheelAlignmentCategory />
            <Container fluid className="testimonials-bg">
            <div  fluid className="container">
      <Col xs={12} md={12} sm={12}>
     
                        <h1>{t("WheelAlignmentTitle")}</h1>
                        <p align="justify">{t("WheelAlignmentDes")}
                        </p>
                        <h4> {t("CommonWheelAlignment")}
                        </h4>
                        <p align="justify"><b>{t("AlignmentMachines1")}</b> {t("AlignmentMachinesDes")}
                        </p>
                        <p align="justify"><b>{t("WheelClampsTurnplates1")}</b> {t("WheelClampsTurnplatesDes")}
                        </p>
                        <p align="justify"><b>{t("AlignmentLifts1")}</b> {t("AlignmentLiftsDes")}
                        </p>
                        <p align="justify">{t("WheelAlignmentLast")}
                        </p>
                        
                       
                       
                    </Col></div></Container>



            <Footer />
        </div>
    );
};

export default WheelAlignment;
