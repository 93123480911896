import React, { useState, useEffect,useContext } from 'react';
import ProductBox from '../ProductBox/Productbox';
import SliderwithMenu from '../ProductBox/Slider';
import axios from 'axios';
// import { Col,Modal } from 'react-bootstrap';
import { BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Col, Nav, Container, Card, Button, Row, Modal, Form, ModalHeader, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';

function BestDeals() {

  const [dealproducts, setDealProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');
  const [show, setShow] = useState();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState(localStorage.getItem("carmake"));
  const [selectedCarModel, setSelectedCarModel] = useState(localStorage.getItem("carmodel"));
  const [make, setMake] = useState(false);
  const [modal, setModal] = useState(false);
  const [state, setState] = useState();
  const [stateId, setIdState] = useState();
  const [carData, setCarData] = useState(false);
  const [slugId, setSlugId] = useState();

  const { t } = useTranslation();
  const {item,setItem} = useContext(UserContext);
  useEffect(() => {
    fetchdealproducts(0);
    getMachineId();
    fetchCarMake();
  }, []);

  const token = localStorage.getItem('token');

  async function fetchdealproducts(id) {
    
    axios.get(BASE_URL + '/api/best-deal-product/'+ id, {
    }).then(({ data }) => {
     
      setDealProducts(data.data);
      
    })
  }


  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }

  }

  function handleSubmitOffline(id, option) {
    setOpen(true)
    setShow(false)
    const data = { "device_id": machineId, "product_id": id, "product_option_id": option }
    axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
      setModalData(data)
      setItem(data.item)
      if (data.status === "success") {
        setError('')

        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/shopping-cart")
        document.getElementById("otherService").reset();

      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  const fetchCarMake = async () => {
    await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {
      setCarMake(data.data)
    })
  }
  async function fetchCarModel(data) {
    const car = new String(data)

    const carMake = car.split("_")
    const CarMakeId = carMake[0]
    setSelectedCarMake(localStorage.setItem('cardata', carMake[1]));
    await axios.get(BASE_URL + '/api/car-model/' + CarMakeId).then(({ data }) => {
      setCarModel(data.data)
    }).catch(({ response }) => {

    })
  }

  function handleSubmit(id, option) {
    setOpen(true)
    setShow(false)
    setError('');
    const data = { 'product_id': id, 'product_option_id': option }
    axios.post(BASE_URL + '/api/store-cart', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setModalData(data)
      setItem(data.item)
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }



  return (
    <SliderwithMenu name={t("prodDeals")} boldname={t("productTab.prod")}  product={fetchdealproducts} categories={dealproducts}>
      {dealproducts.map(i => (
        <Col md={3} sm={4} xs={6}>
          <ProductBox
           
            name={localStorage.getItem('language') == 'ar' ? i.name_ar && i.name_ar : i.name && i.name}
            
            img={i.image}
            BtnCart={t("productTab.cartBtn")}
            
           
            productDetail={localStorage.getItem('cardata') && localStorage.getItem('carmodeldata') ? () => navigate("/product-detail/" + i.slug) : () => {navigate("/product-detail/" + i.slug)}}
          />
          
        </Col>
      ))}
         
    </SliderwithMenu>
  )
}

export default BestDeals;