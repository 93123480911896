import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Navbar/Menubar_new";
import { Helmet } from "react-helmet";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Card,
    Button
} from "react-bootstrap";
import { Link,useParams } from "react-router-dom";
import CarImg from "../images/carimg.jpg";
import axios from 'axios';
import { BASE_URL } from "../config";
const BlogDetail = () => {
    const {t} = useTranslation();
    const [blogdata,setBlogData]=useState([]);
    let { slug } = useParams();
    useEffect(()=>{
        fetchcarrier();
    },[])
    const fetchcarrier = async()=>{
        await axios.get(BASE_URL+'/api/blogdetail/'+slug).then(({data})=>{
            setBlogData(data.data)
        })
    }
    const language = localStorage.getItem("language");

    const blogData = blogdata.content;
    const [metaData, setMetaData] = useState([]);

  return (
    <div className="overflow">
     <Helmet>
            <title>{`${blogdata.title || blogdata.title } - Cube Oman` }</title>
            <meta name="description" content={blogdata.content} />
            </Helmet>
     
    <Header />
    <Menubar />

    <Container fluid className="company-breadcrumb">
        <Row>
            <Col className="breadcrumb-padding" xs={12} md={8}>
                <h1>{language == "ar" ? blogdata.title_ar && blogdata.title_ar : blogdata.title && blogdata.title }</h1>
                
            </Col>
            <Col xs={12} md={4}>
            <img src={BASE_URL+'/storage/'+blogdata.image} alt="" className="blogImg" />
            </Col>
        </Row>
    </Container>

    <Container className="careerCont">
    
        <Col md={12} xs={12} >
        <Row>
                   
                     <Col xs={12} md={12}>
                    <h2>{language == "ar" ? blogdata.title_ar && blogdata.title_ar : blogdata.title && blogdata.title}</h2>
                    
                    </Col>
                    
                    
                    <Col md={12} xs={12}>
                    <div className="text-muted" dangerouslySetInnerHTML={{__html:language == "ar" ? blogdata.content_ar && blogdata.content_ar : blogData && blogData }}></div> 
                    
                    </Col>
        </Row>
        </Col>
    </Container>
    <Footer />
</div>
  )
}

export default BlogDetail